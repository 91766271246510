<template>
  <v-container>
    <v-data-table
      v-model="videosToAdd"
      :loading="loading"
      :headers="headers"
      :items="videos"
      :disable-pagination="true"
      hide-default-footer
      sort-by="seriesID"
      item-key="seriesID"
      sort-desc
      show-select
    >
      <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="searchTerm"
                append-icon="mdi-magnify"
                class="ml-auto"
                label="Search..."
                hide-details
                single-line
                clearable
                @change="filterTable"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                class="mx-auto"
                v-model="searchCat"
                :items="categoryOptions"
                item-text="tagName"
                item-value="id"
                label="Select a category."
                clearable
                v-on:change="filterTable"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select
                :items="pageOptions"
                v-model="rowsPerPage"
                append-icon="mdi-chevron-down"
                class="ml-auto"
                label="Rows per Page"
                hide-details
                v-on:change="filterTable"
              >
              </v-select>
            </v-col>
              <v-col cols="12" sm="6" md="2" class="text-sm-center">
                <v-btn
                  color="green darken-1"
                  class="mt-4 mr-0"
                  @click="addToGroup"
                >
                  Add Videos to Group
                </v-btn>
              </v-col>
          </v-row>
          <v-spacer></v-spacer>
      </template>
      <template v-slot:no-data>
        <h1>Sorry, we couldn't find anything that matched that search.</h1>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
      <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
    </div>
  </v-container>
</template>

<script>

import videoManagement from '@/Services/Videos/VideoManagement'

export default {
  name: 'VideoSelect',
  data: () => ({
    videosToAdd: [],
    videosToEmit: [],
    searchURL: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    categoryOptions: [
      {
        text: 'Please Select',
        value: null
      }
    ],
    loading: true,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'seriesID'
      },
      { text: 'Name', value: 'name' },
      { text: 'Video Count', value: 'videoCount' }
    ],
    videos: [],
    searchTerm: null,
    searchCat: null
  }),
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.loading = true
      await videoManagement.init(this.rowsPerPage)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load videos.'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
        })
      await videoManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    async previousPage () {
      this.loading = true
      await videoManagement.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await videoManagement.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async filterTable () {
      this.loading = true
      const term = this.searchTerm
      const cat = this.searchCat
      let url = 'articles/videos/admin?count=' + this.rowsPerPage
      let extraParam = null
      if (term && cat) {
        extraParam = '&term=' + term + '&cat=' + cat
      } else if (term && !cat) {
        extraParam = '&term=' + term
      } else if (!term && cat) {
        extraParam = '&cat=' + cat
      }
      if (extraParam !== null) {
        url = url + extraParam
      }
      if (extraParam !== null) {
        this.searchURL = '?count=' + this.rowsPerPage + extraParam
      } else if (extraParam === null) {
        this.searchURL = '?count=' + this.rowsPerPage
      }
      await videoManagement.filterTable(url)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'videos', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve results'
            this.snackTimeout = 3000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.$set(this, 'videos', [])
          this.loading = false
        })
    },
    addToGroup () {
      this.videosToAdd.forEach(element => {
        this.videosToEmit.push(element.seriesID)
      })
      this.$emit('videosAdded', this.videosToEmit)
      this.videosToEmit = []
      this.videosToAdd = []
    }
  }
}
</script>
