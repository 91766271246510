<template>
  <v-container
    id="portal-management"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-book-open-page-variant-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-3 font-weight-light">
          {{group.name}}
        </div>
        <div class="display-2 font-weight-light">
          {{group.description}}
        </div>
      </template>
      <v-row>
        <v-col class="text-sm-center">
          <v-dialog v-model="addItem" :retain-focus="false" fullscreen transition="dialog-bottom-transition" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="mt-4 mr-0"
              >
                Add Items
                <v-icon
                  right
                  dark
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Add Item to Group</span>
              </v-card-title>
              <v-card-text>
                <h3>You're adding: {{listingsToAdd.length}} Listings, {{videosToAdd.length}} Videos and {{resourcesToAdd.length}} Resources.</h3>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="addItemType" :items="addItemSelect" item-text="text" item-value="value" label="Item Type" required></v-select>
                    </v-col>
                    <v-col v-if="addItemType === 1" cols="12">
                      <ListingSelect @listingsAdded="getAddedListings"/>
                    </v-col>
                    <v-col v-if="addItemType === 2" cols="12">
                      <VideoSelect @videosAdded="getAddedVideos" />
                    </v-col>
                    <v-col v-if="addItemType === 3" cols="12">
                      <ResourceSelect @resourcesAdded="getAddedResources" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-on:click.stop="closeAddItem" color="red darken-1">Nevermind</v-btn>
                <v-btn v-on:click.stop="addDataToGroup" color="green darken-1">Add to Group</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="listingSelected"
            :headers="headers"
            :items="listings"
            item-key="name"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Listings</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  dark
                  class="mb-2"
                  :disabled="listingSelected.length === 0"
                  @click="deleteListings()"
                >
                  Remove Listings
                </v-btn>
                <v-dialog v-model="listingDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Are you sure?</v-card-title>
                    <br />
                    <v-card-subtitle class="subtitle-1 text-center">This will remove the listing(s) from the group</v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteListingConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="videoSelected"
            :headers="videoHeaders"
            :items="videos"
            item-key="name"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Videos</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  dark
                  class="mb-2"
                  :disabled="videoSelected.length === 0"
                  @click="deleteVideos()"
                >
                  Remove Videos
                </v-btn>
                <v-dialog v-model="videoDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Are you sure?</v-card-title>
                    <br />
                    <v-card-subtitle class="subtitle-1 text-center">This will remove the video(s) from the group</v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteVideoConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="resourceSelected"
            :headers="headers"
            :items="resources"
            item-key="name"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Resources</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  dark
                  class="mb-2"
                  :disabled="resourceSelected.length === 0"
                  @click="deleteResources()"
                >
                  Remove Resources
                </v-btn>
                <v-dialog v-model="resourceDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Are you sure?</v-card-title>
                    <br />
                    <v-card-subtitle class="subtitle-1 text-center">This will remove the resource(s) from the group</v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteResourceConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>
<script>

import groupDetails from '@/Services/Groups/GroupDetails'
import editGroup from '@/Services/Groups/EditGroup'
import ListingSelect from '@/views/dashboard/tables/ListingSelect'
import VideoSelect from '@/views/dashboard/tables/VideoSelect'
import ResourceSelect from '@/views/dashboard/tables/ResourceSelect'

export default {
  name: 'MarketingDetails',
  components: {
    ListingSelect,
    VideoSelect,
    ResourceSelect
  },
  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    listingsToAdd: [],
    videosToAdd: [],
    resourcesToAdd: [],
    addItem: false,
    addItemType: null,
    addItemData: [],
    searchItem: null,
    itemLoading: false,
    itemHeaders: [],
    addItemSelect: [
      { value: null, text: 'Select an item type' },
      { value: 1, text: 'Listing' },
      { value: 2, text: 'Video' },
      { value: 3, text: 'Resource' }
    ],
    listingDelete: false,
    videoDelete: false,
    resourceDelete: false,
    listingSelected: [],
    videoSelected: [],
    resourceSelected: [],
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'uniqueID'
      },
      { text: 'Name', value: 'name' },
      { text: 'Category', value: 'category' },
      { text: 'Published Date', value: 'publishedDate' }
    ],
    videoHeaders: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'seriesID'
      },
      { text: 'Name', value: 'name' },
      { text: 'Category', value: 'category' },
      { text: 'Published Date', value: 'publishedDate' }
    ],
    group: {
      name: '',
      description: '',
      id: null,
      sortOrder: null
    },
    listings: [],
    videos: [],
    resources: [],
    groupUpdateObject: {
      opType: null,
      listings: [],
      videos: [],
      resources: []
    }
  }),
  created () {
    this.group.id = this.$route.params.id
    this.initialize(this.group.id)
  },
  methods: {
    async initialize (id) {
      this.loadSnack()
      await groupDetails.getGroupDetails(id)
        .then((res) => {
          if (res.status === 200) {
            this.group.name = res.data.name
            this.group.description = res.data.description
            this.$set(this, 'listings', res.data.listings)
            this.$set(this, 'videos', res.data.videos)
            this.$set(this, 'resources', res.data.resources)
            this.finishSnack()
          } else {
            this.errorSnack(res.data.title)
          }
        })
    },
    deleteListings () {
      this.listingDelete = true
    },
    async deleteListingConfirm () {
      this.loadSnack()
      this.groupUpdateObject.opType = 2
      this.listingSelected.forEach(element => {
        this.groupUpdateObject.listings.push(element.uniqueID)
      })
      await editGroup.editGroup(this.group.id, this.groupUpdateObject)
        .then((res) => {
          if (res.status === 200) {
            this.listingDelete = false
            this.finishSnack()
            this.resetData()
            this.initialize(this.group.id)
          } else {
            this.errorSnack(res.data.title)
          }
        })
    },
    deleteVideos () {
      this.videoDelete = true
    },
    async deleteVideoConfirm () {
      this.loadSnack()
      this.groupUpdateObject.opType = 2
      this.videoSelected.forEach(element => {
        this.groupUpdateObject.videos.push(element.seriesID)
      })
      await editGroup.editGroup(this.group.id, this.groupUpdateObject)
        .then((res) => {
          if (res.status === 200) {
            this.videoDelete = false
            this.finishSnack()
            this.resetData()
            this.initialize(this.group.id)
          } else {
            this.errorSnack(res.data.title)
          }
        })
    },
    deleteResources () {
      this.resourceDelete = true
    },
    async deleteResourceConfirm () {
      this.loadSnack()
      this.groupUpdateObject.opType = 2
      this.resourceSelected.forEach(element => {
        this.groupUpdateObject.resources.push(element.uniqueID)
      })
      await editGroup.editGroup(this.group.id, this.groupUpdateObject)
        .then((res) => {
          if (res.status === 200) {
            this.resourceDelete = false
            this.finishSnack()
            this.resetData()
            this.initialize(this.group.id)
          } else {
            this.errorSnack(res.data.title)
          }
        })
    },
    closeDelete () {
      this.listingDelete = false
      this.videoDelete = false
      this.resourceDelete = false
    },
    closeAddItem () {
      this.listingsToAdd = []
      this.videosToAdd = []
      this.resourcesToAdd = []
      this.addItem = false
    },
    getAddedListings (value) {
      value.forEach(element => {
        if (!this.listingsToAdd.includes(element)) {
          this.listingsToAdd.push(element)
        }
      })
      this.addItemType = null
    },
    getAddedVideos (value) {
      value.forEach(element => {
        if (!this.videosToAdd.includes(element)) {
          this.videosToAdd.push(element)
        }
      })
      this.addItemType = null
    },
    getAddedResources (value) {
      value.forEach(element => {
        if (!this.resourcesToAdd.includes(element)) {
          this.resourcesToAdd.push(element)
        }
      })
      this.addItemType = null
    },
    async addDataToGroup () {
      this.loadSnack()
      this.groupUpdateObject.opType = 1
      this.groupUpdateObject.listings = this.listingsToAdd
      this.groupUpdateObject.videos = this.videosToAdd
      this.groupUpdateObject.resources = this.resourcesToAdd
      await editGroup.editGroup(this.group.id, this.groupUpdateObject)
        .then((res) => {
          if (res.status === 200) {
            this.addItem = false
            this.finishSnack()
            this.resetData()
            this.initialize(this.group.id)
          } else {
            this.addItem = false
            this.errorSnack(res.data.title)
          }
        })
    },
    resetData () {
      this.groupUpdateObject = {
        opType: null,
        listings: [],
        videos: [],
        resources: []
      }
      this.listingsToAdd = []
      this.videosToAdd = []
      this.resourcesToAdd = []
      this.addItemType = null
      this.listingSelected = []
      this.resourceSelected = []
      this.videoSelected = []
    },
    loadSnack () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartLoaded = true
    },
    errorSnack (errorMessage) {
      if (errorMessage) {
        this.snackColor = 'error'
        this.snackText = errorMessage
        this.snackTimeout = 5000
      } else {
        this.snackColor = 'error'
        this.snackText = 'Error occured'
        this.snackTimeout = 5000
      }
    }
  }
}
</script>
